import type { Values } from '../types/utils';

export const PRICING_OPTION_FARE_ASSESSMENT = {
  UNKNOWN: 'UNKNOWN',
  INCLUDED: 'INCLUDED',
  FEE: 'FEE',
  UNAVAILABLE: 'UNAVAILABLE',
} as const;

export type PricingOptionFareAssessment = Values<typeof PRICING_OPTION_FARE_ASSESSMENT>;
