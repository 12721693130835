import * as ADVERTS from './adverts';
import * as API_KEYS from './apiKeys';
import { CABIN_CLASS } from './cabinClass';
import * as CONFIG_SERVICE_KEYS from './configServiceKeys';
import * as CULTURE from './culture';
import * as DATE_FORMATS from './dateFormats';
import ENV_CONFIG_KEYS from './envConfigKeys';
import * as EVENT_NAMES from './eventNames';
import * as FARE_ATTRIBUTES from './fareAttributes';
import * as FEATURE_DECISION_KEYS from './featureDecisionKeys';
import * as LOGGING from './logging';
import { MONEY_UNIT } from './money';
import { PAGE_GROUPS } from './pageGroups';
import { PRICING_OPTION_FARE_ASSESSMENT } from './pricingOption';
import * as ROUTES from './routes';
import { SCREEN_NAMES } from './screenName';
import SEARCH_TYPES from './searchTypes';
import SEO from './seo';
import { SORT_TYPES } from './sort';
import { TRIP_TYPES } from './tripTypes';

const ACORN_PROJECT_NAME = 'acorn';
const ACORN_DAYVIEW_GTM_EVENT_NAME = 'acorn_day_view_loaded' as const;
const ACORN_BOOKING_PANEL_GTM_EVENT_NAME = 'acorn_booking_panel_loaded' as const;

export {
  ACORN_BOOKING_PANEL_GTM_EVENT_NAME,
  ACORN_DAYVIEW_GTM_EVENT_NAME,
  ACORN_PROJECT_NAME,
  ADVERTS,
  API_KEYS,
  CABIN_CLASS,
  CONFIG_SERVICE_KEYS,
  CULTURE,
  DATE_FORMATS,
  ENV_CONFIG_KEYS,
  EVENT_NAMES,
  FARE_ATTRIBUTES,
  FEATURE_DECISION_KEYS,
  LOGGING,
  MONEY_UNIT,
  PAGE_GROUPS,
  PRICING_OPTION_FARE_ASSESSMENT,
  ROUTES,
  SCREEN_NAMES,
  SEARCH_TYPES,
  SEO,
  SORT_TYPES,
  TRIP_TYPES,
};
