import type { Values } from '../types/utils';

export const MONEY_UNIT = {
  UNIT_UNSPECIFIED: 'UNIT_UNSPECIFIED',
  UNIT_WHOLE: 'UNIT_WHOLE',
  UNIT_CENTI: 'UNIT_CENTI',
  UNIT_MILLI: 'UNIT_MILLI',
  UNIT_MICRO: 'UNIT_MICRO',
} as const;

export type MoneyUnit = Values<typeof MONEY_UNIT>;

export type Money = {
  currency_code?: string;
  amount?: string;
  unit?: MoneyUnit;
};
