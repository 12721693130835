export const YEAR_MONTH = 'yMMMM';
export const YEAR_MONTH_ABBR = 'yMMM';
export const ABBR_MONTH_DAY = 'MMMEd';
export const ABBR_MONTH_DAY_NO_WEEKDAY = 'MMMd';
export const YEAR_MONTH_DAY_DASHED = 'yyyy-MM-dd';
export const YEAR_MONTH_DAY_SHORT_DASHED = 'yy-MM-dd';
export const YEAR_MONTH_DAY_FULL = 'yyyyMMdd';
export const YEAR_MONTH_DAY_SHORT = 'yyMMdd';
export const YEAR_MONTH_FULL_DASHED = 'yyyy-MM';
export const YEAR_MONTH_SHORT_DASHED = 'yy-MM';
export const YEAR_MONTH_FULL = 'yyyyMM';
export const YEAR_MONTH_SHORT = 'yyMM';
