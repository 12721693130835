import { isAcornRequest } from './utils/isAcornRequest';
import { MARK_NAMES, perfMark } from './utils/performance';

// Setting the Start Mark for acorn traffic
if (isAcornRequest()) {
  perfMark(MARK_NAMES.APP_INIT);
}

if (!isAcornRequest()) {
  // Import it to call the context promise immediately. (The executor is called synchronously when the Promise is constructed: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Promise/Promise)
  // Otherwise, the promise will be called after lazy loading the bootstrap, it will impact the performance.
  import(
    /* webpackChunkName: "ContextPromise" */
    './utils/contextPromise'
  );
}

import(
  /* webpackChunkName: "Bootstrap" */
  './bootstrap'
)
  .then((bootstrap) => bootstrap.run())
  .catch((error) => {
    throw error;
  });

export {};
