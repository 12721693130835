import { GLOBAL } from 'saddlebag-browser';

import type { AcornInitData, ContextBody } from 'common/types/context';

import type { DynamicStore } from '../state/createDynamicStore';
import type { Store } from 'redux';
import type { Action } from 'webapp-acorn/src/actions/types';
import type { State } from 'webapp-acorn/src/state';

export const isAcornRequest = (): boolean => {
  const $window = GLOBAL.getWindow();
  return $window.__internal?.isAcornRequest || false;
};

// It is just used to check if the context type: Acorn (AcornInitData) or Banana (ContextBody | null)
export const isAcornInitData = (
  context: AcornInitData | ContextBody | null,
): context is AcornInitData => isAcornRequest();

export const isAcornStore = (
  store: DynamicStore | Store<State, Action>,
): store is Store<State, Action> => isAcornRequest();
