import { GLOBAL } from 'saddlebag-browser';

import { HISTORY_CHANGED_MARKER } from '../constants/performance';

import { logWarn } from './logger';

export const MARK_NAMES = {
  APP_INIT: 'APP_INIT',
  DAYVIEW_SUBROUTE_MOUNTED: 'DAYVIEW_SUBROUTE_MOUNTED',
};

const $window = GLOBAL.getWindow();

type DetailType = { [field: string]: string | number | undefined };

export const hasPerformanceSupport = !!(
  $window.performance &&
  $window.performance.getEntriesByName &&
  $window.performance.mark &&
  $window.performance.measure &&
  $window.performance.getEntriesByType
);

const getDurationSinceLoaded = (event: PerformanceEntry) => {
  const isMeasureMetric = event.entryType === 'measure';
  if (isMeasureMetric) {
    return event.duration;
  }
  const { startTime } = $window.performance.getEntriesByType('navigation')[0];
  const endTime = event.startTime;
  return endTime - startTime;
};

export const getDuration = (eventName: string): number => {
  if (!hasPerformanceSupport) return -1;
  const event = $window.performance.getEntriesByName(eventName);
  if (!event || !event.length) {
    logWarn({
      component: 'performance.getDuration',
      message: `Failed to get the duration of ${eventName}, return -1`,
    });
    return -1;
  }
  return Math.floor(getDurationSinceLoaded(event[0]));
};

export const mark = (eventName: string, sendOnce: boolean = false, detail?: DetailType) => {
  if (!hasPerformanceSupport) {
    return;
  }

  const shouldMark =
    !sendOnce || (sendOnce && !$window.performance.getEntriesByName(eventName).length);

  if (!shouldMark) return;

  /* Using measure simulate mark to achieve compatibility
   * that performance entry buffer wouldn't reset if change front-end routing
   */
  if (
    $window.performance.getEntriesByName(HISTORY_CHANGED_MARKER).length &&
    eventName !== HISTORY_CHANGED_MARKER
  ) {
    if (detail) {
      $window.performance.measure(eventName, { start: HISTORY_CHANGED_MARKER, detail });
    } else {
      $window.performance.measure(eventName, HISTORY_CHANGED_MARKER);
    }
    return;
  }

  if (detail) {
    $window.performance.mark(eventName, { detail });
  } else {
    $window.performance.mark(eventName);
  }
};

const getPerformance = (): Performance | undefined => $window.performance;

const markAlreadyExists = (markName: string) => {
  const performance = getPerformance();

  if (!performance?.getEntriesByName) return false;

  return (performance.getEntriesByName(markName) || []).length > 0;
};

export const perfMark = (markName: string) => {
  const performance = getPerformance();

  if (!markAlreadyExists(markName) && performance?.mark) {
    performance.mark(markName);
  }
};

export const perfMeasure = (
  measureName: string,
  startMark: string,
  endMark: string,
): PerformanceMeasure | undefined => {
  const performance = getPerformance();

  if (markAlreadyExists(startMark) && markAlreadyExists(endMark)) {
    return performance?.measure && performance.measure(measureName, startMark, endMark);
  }

  return undefined;
};

export const perfClearMarks = (markNames: string[]) => {
  const performance = getPerformance();

  markNames.forEach((markName) => {
    performance?.clearMarks && performance.clearMarks(markName);
  });
};
