import type { Values } from '../types/utils';

export const SERVICE = {
  DFP: 'dfp',
  DS: 'SPONSORED_SERVICE',
} as const;

export type Service = Values<typeof SERVICE>;

export const PLATFORM = 'MOBILE_WEB';
export const PLACEMENT_IDS = {
  BOOKING_PANEL: 'mobile.flights.booking-panel/top',
  HOMEPAGE_AD_CARD: 'mobile.flights.homepage/slot',
} as const;
export const HOMEPAGE_AD_CARD_ADSLOT_ID = 'adslot-138c730c';
export const HOMEPAGE_NAV_CARD_1_SLOT_ID = 'adslot-navcard1';
export const HOMEPAGE_NAV_CARD_2_SLOT_ID = 'adslot-navcard2';
export const HOMEPAGE_NAV_CARD_3_SLOT_ID = 'adslot-navcard3';
export const HOMEPAGE_NAV_CARD_4_SLOT_ID = 'adslot-navcard4';
export const HOMEPAGE_NAV_CARD_5_SLOT_ID = 'adslot-navcard5';
export const HOMEPAGE_NAV_CARD_6_SLOT_ID = 'adslot-navcard6';
export const HOMEPAGE_NAV_CARD_7_SLOT_ID = 'adslot-navcard7';
export const BOOKING_PANEL_AD_SLOT_ID = 'adslot-1';
export const PAGE_TYPES = {
  HOME: 'flights.home',
  DAY_VIEW: 'flights.dayview',
  BOOKING_PANEL: 'fligths.booking-panel',
} as const;
export const NONE_AVAILABLE = 'NONE_AVAILABLE';
export const EFS_GRAPHIC_PROMO_COMPONENT_ID = 'fmt.graphicPromo';
export const EFS_HOMEPAGE_HERO_COMPONENT_ID = 'fmt.homepageHero';
export const EFS_PLACEHOLDER_PRODUCT_COMPONENT_ID = 'fmt.placeholderProduct';
export const EFS_NAV_CARD_COMPONENT_ID = 'fmt.navCard';
export const EFS_GRAPHIC_PROMO_EVERYWHERE_CTA_URL = 'EXPLORE_EVERYWHERE';
export const PLATFORM_INTERVAL_BETWEEN_ADS = 5;
