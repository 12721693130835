// Follows the Web Production Standard
// https://github.skyscannertools.net/tech-initiatives/production-standards/blob/master/web/required/dates-timezone-library.md
// Currently is duplicated on both Webapp and Server until /common can support importing npm packages in its files
import { parseISO } from 'date-fns/parseISO';
import Localisation, {
  currency as saddlebagCurrency,
  date as saddlebagDate,
} from 'saddlebag-localisation';

import { DATE_FORMATS } from 'common/constants';
import type { ContextCulture as Culture, ContextCldr as Cldr } from 'common/types/context';
import type { DateString, WeekDay } from 'common/types/dateString';
import type { DateTime, FullDomainName } from 'common/types/localisation';

type FormatCurrency = (value: number) => string;
type FormatCurrencyShort = (
  value: number,
  currencyCode: string,
  options?: {
    minimumSignificantDigits?: number;
    maximumSignificantDigits?: number;
    compact?: 'short' | 'long';
  },
) => string;
type FormatDate = (datetime: DateTime, format?: string) => string;
type FormatDateCustom = (datetime: DateTime, datePattern: string) => string;
type FormatTime = (datetime: DateTime, format?: string) => string;
type FormatDateTime = (datetime: DateTime, format: string) => string;
type FormatDateNonlocalised = (datetime: DateTime, format?: string) => DateString;
type FormatNumber = (value: number, options?: Intl.NumberFormatOptions) => string;

let localisation: {
  getDaysOfWeek: () => WeekDay[];
  getMonthName: (date: DateTime) => string;
  getFirstDay: () => number;
  formatCurrency: FormatCurrency;
  formatCurrencyShort: FormatCurrencyShort;
  formatDate: FormatDate;
  formatTime: FormatTime;
  formatDateTime: FormatDateTime;
  formatNumber: FormatNumber;
  formatDateNonlocalised: FormatDateNonlocalised;
  formatDateCustom: FormatDateCustom;
  monthNames: (format?: string) => String[];
};

let cldr: Cldr;
let culture: Culture;
let fullDomainName: FullDomainName;

// FullDomainName
const setFullDomainName = (fullDomainNameStr: FullDomainName) => {
  fullDomainName = fullDomainNameStr;
};
const getFullDomainName = (): FullDomainName => fullDomainName;

// CLDR
const setCldr = (cldrData: Cldr) => {
  Localisation.enhance(saddlebagCurrency, saddlebagDate);
  localisation = Localisation(cldrData);
  cldr = { ...cldrData };
};
const getCldr = (): Cldr => cldr;

// Culture
const setCulture = (cultureData: Culture) => {
  culture = { ...cultureData };
};
const getCulture = (): Culture => culture;

// parsing with date-fns
const parseDateTime = (datetime: DateTime): Date => {
  if (typeof datetime === 'string') {
    return parseISO(datetime);
  }
  return datetime;
};

// getters
const getDaysOfWeek = () => localisation.getDaysOfWeek();
const getMonthName = (date: DateTime): string => localisation.getMonthName(parseDateTime(date));
const getFirstDay = (): number => localisation.getFirstDay();
const getYearMonthDay = (dateStr: DateString) => {
  const date = parseDateTime(dateStr);
  return {
    year: date.getFullYear(),
    month: date.getMonth() + 1,
    day: date.getDate(),
  };
};
const getMonthStrings = (format?: string): String[] => localisation.monthNames(format);
const isMonthDate = (dateStr: DateString | undefined) => {
  if (!dateStr) return false;

  return dateStr === formatDateNonlocalised(dateStr, DATE_FORMATS.YEAR_MONTH_FULL_DASHED);
};

const isSpecificDate = (dateStr: DateString | undefined) => {
  if (!dateStr) return false;

  return dateStr === formatDateNonlocalised(dateStr, DATE_FORMATS.YEAR_MONTH_DAY_DASHED);
};

// formatters
const formatCurrency: FormatCurrency = (amount) => localisation.formatCurrency(amount);

const formatCurrencyShort: FormatCurrencyShort = (amount, currencyCode, options) =>
  localisation.formatCurrencyShort(amount, currencyCode, options);

const formatDate: FormatDate = (datetime, format) =>
  localisation.formatDate(parseDateTime(datetime), format);

const formatDateCustom: FormatDateCustom = (datetime, datePattern) =>
  localisation.formatDateCustom(parseDateTime(datetime), datePattern);

const formatTime: FormatTime = (datetime, format) =>
  localisation.formatTime(parseDateTime(datetime), format);

const formatDateTime: FormatDateTime = (datetime, format) =>
  localisation.formatDateTime(parseDateTime(datetime), format);

const formatDateNonlocalised: FormatDateNonlocalised = (
  datetime,
  format = DATE_FORMATS.YEAR_MONTH_DAY_DASHED,
) => localisation.formatDateNonlocalised(parseDateTime(datetime), format);

const formatNumber: FormatNumber = (value: number, options: Intl.NumberFormatOptions = {}) =>
  value.toLocaleString(culture.locale, options);

export {
  // singletons
  setCldr,
  getCldr,
  setCulture,
  getCulture,
  setFullDomainName,
  getFullDomainName,
  // getters
  getDaysOfWeek,
  getMonthName,
  getFirstDay,
  getYearMonthDay,
  getMonthStrings,
  isMonthDate,
  isSpecificDate,
  // formatters
  formatCurrency,
  formatDate,
  formatDateCustom,
  formatTime,
  formatDateTime,
  formatNumber,
  formatDateNonlocalised,
  formatCurrencyShort,
  // parsers
  parseDateTime,
};
