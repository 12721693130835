// Unsupported markets, verified with Tigon

export const UNSUPPORTED_MARKETS = ['AQ', 'BL', 'BQ', 'CW', 'KO', 'SS', 'SX', 'TL'];

export const reducer = (acc: any, key: any) => {
  acc[key] = true;
  return acc;
};

export const CORE_LOCALE_GROUP = 'CORE';

export const UNSUPPORTED_MARKETS_MAPPING = UNSUPPORTED_MARKETS.reduce(reducer, {});
