import type { Values } from '../types/utils';

export const BAGGAGE_TYPE = {
  CHECKED_BAGGAGE: 'checkedBaggage',
  CABIN_BAGGAGE: 'cabinBaggage',
} as const;

export type BaggageType = Values<typeof BAGGAGE_TYPE>;

export const ASSESSMENT_TYPE = {
  UNKNOWN: 'UNKNOWN', // baggage information unknown
  INCLUDED: 'INCLUDED', // free baggage included
  FEE: 'FEE', // baggage included with a fee
  UNAVAILABLE: 'UNAVAILABLE', // baggage not included
} as const;

export type AssessmentType = Values<typeof ASSESSMENT_TYPE>;
