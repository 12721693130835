import type { Values } from '../types/utils';

export const SCREEN_NAMES = {
  HOME_PAGE: 'HOME_PAGE',
  DAY_VIEW: 'DAY_VIEW',
  COMBINED_RESULTS: 'COMBINED_RESULTS',
  COMBINED_ENTRY_PAGE: 'COMBINED_ENTRY_PAGE',
  COMBINED_EXPLORE: 'COMBINED_EXPLORE',
  CULTURE_SELECTOR: 'CultureSelector',
} as const;

export type ScreenName = Values<typeof SCREEN_NAMES>;
