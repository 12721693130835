import type { Values } from '../types/utils';

export const PAGE_GROUPS = {
  BOOKING_PANEL: 'booking-panel',
  BOOKING_PANEL_ACORN: 'booking-panel-acorn',
  CABIN_TRAVELLERS_PICKER: 'cabin-travellers-picker',
  COMBINED_ENTRY_PAGE: 'combined-entry-page',
  COMBINED_EXPLORE: 'combined-explore',
  COMBINED_RESULTS: 'combined-results',
  DATE_PICKER: 'date-picker',
  DAY_VIEW: 'day-view',
  DESTINATION_SELECTOR: 'destination-selector',
  HOME: 'home',
  NO_PAGE_TYPE_PROVIDED: 'no-page-type-provided',
  UNKNOWN_PAGE_GROUP: 'unknown-page-group',
  ORIGIN_SELECTOR: 'origin-selector',
} as const;

export type PageGroup = Values<typeof PAGE_GROUPS> | `unrecognised-page-type_${string}`;
