export default {
  BASE_URL: 'baseURL',
  CACHE: 'cache',
  DEPLOY_COLOUR: 'deployColour',
  DEPLOY_REGION: 'deployRegion',
  ENV: 'env',
  GET_STATIC_ASSETS: 'getStaticAssets',
  GET_TRANSLATIONS_PATH: 'getTranslationPath',
  INDEX_PATH: 'indexPath',
  INDEX_CONTENT_PATH: 'indexContentPath',
  LOGGING: 'logging',
  METRICS: 'metrics',
  METRICS_ACORN: 'metricsAcorn',
  READINESS_CACHE: 'readinessNeedsCache',
  REDIRECT: 'redirect',
  USE_ACORN_ROUTE: 'useAcornRoute',
  OPENTELEMETRY: 'opentelemetry',
  USE_DEV_PROXY: 'useDevProxy',
  USE_MOCKED_ENDPOINTS: 'useMockedEndpoints',
  OVERRIDE_INTERNAL_USER_STATE: 'overrideInternalUserState',
  MOCK_CONFIG_PROVIDER: 'mockConfigProvider',
  SERVICE_NAME: 'serviceName',

  // Acorn specific Env Keys
  IS_CELLS: 'isCells',
  DEVELOPMENT_CLIENT_URL: 'developmentClientUrl',
  ENABLE_SINGLE_BUILD: 'enable_single_build',
};
