import {
  logError as saddlebagLogError,
  logWarn as saddlebagLogWarn,
  logOperationalEvent as saddlebagLogOperationalEvent,
} from 'saddlebag-logger';

import { getCulture } from '../singleton/localisation';

import type { Maybe } from '../types/utils';

type CommonLogFields = {
  component: string;
  message: string;
};

export type LogWarnType = CommonLogFields & {
  [key: string]: Maybe<string | number>;
};

export type LogErrorType = CommonLogFields & {
  error: Error;
  [key: string]: Maybe<string | number | Error>;
};

const getCommonLogFields = () => {
  const { currency, locale } = getCulture() || {};

  return {
    currency,
    locale,
  };
};

export const logError = (fields: LogErrorType) => {
  const { error, ...restFields } = fields;
  const { component } = restFields;

  try {
    saddlebagLogError(error, {
      ...getCommonLogFields(),
      ...restFields,
    });
  } catch (loggingError) {
    logSaddlebagError(loggingError, component, 'error');
  }
};

export const logWarn = (fields: LogWarnType) => {
  const { component } = fields;
  try {
    saddlebagLogWarn({
      ...getCommonLogFields(),
      ...fields,
    });
  } catch (loggingError) {
    logSaddlebagError(loggingError, component, 'warning');
  }
};

const logSaddlebagError = (error: Error, component: string, type: string): void => {
  try {
    saddlebagLogError(error, {
      ...getCommonLogFields(),
      component: 'saddlebag-logger',
      message: `Failed to log ${component} ${type} via saddlebag-logger.`,
    });
    // If this failed, we cannot log anywhere, and we're screwed.
    // This second catch, catches the exception from the second saddlebagLogError
  } catch (saddlebagError) {
    // eslint-disable-next-line no-console
    console.error(`Unable to log ${type}`, saddlebagError);
  }
};

export const logOperationalEvent = saddlebagLogOperationalEvent;
